import React from 'react';
import { Typography } from '@mui/material';
import FasilPage from '../ui/page';

function FasilHome() {
  return (
    <FasilPage>
      <Typography>FASIL SMP Website.</Typography>
    </FasilPage>
  );
}

export default FasilHome;
