import React from 'react';
import PropTypes from 'prop-types';
import useAxios from 'use-axios';
import {
  FormControl, InputLabel,
  MenuItem, Select
} from '@mui/material';

function PlayerSelector(props) {
  const [selectedPlayer, setSelectedPlayer] = React.useState('no_player_selected');
  const { data } = useAxios('/api/v2/player/season/6');
  const { changeCallback } = props;

  const changeSelectedPlayer = (event) => {
    const selectedValue = event.target.value;
    setSelectedPlayer(selectedValue);
    changeCallback(selectedValue);
  };

  return (
    <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
      <InputLabel id="player-select-label">Select a player</InputLabel>
      <Select
        labelId="player-select-label"
        id="player-select"
        value={selectedPlayer}
        label="Selected player"
        onChange={changeSelectedPlayer}
      >
        <MenuItem value="no_player_selected">
          <em>None</em>
        </MenuItem>
        {
            data.map((player) => (
              <MenuItem key={`player-select-${player.uuid}`} value={player.uuid}>{player.name}</MenuItem>
            ))
          }
      </Select>
    </FormControl>
  );
}

PlayerSelector.propTypes = {
  changeCallback: PropTypes.func.isRequired
};

export default PlayerSelector;
