import React from 'react';
import { Box } from '@mui/material';

function FasilPage(props) {
  const { children } = props;

  return (
    <Box ml={2} mb={2} mr={2} mt={1}>
      {children}
    </Box>
  );
}

export default FasilPage;
