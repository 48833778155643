export function formatNumber(number) {
  return new Intl.NumberFormat().format(number);
}

export function percentage(numerator, denominator) {
  if (denominator === 0) {
    return 0;
  }

  return `${formatNumber(100.0 * (numerator / denominator))}%`;
}

export function titleCase(str) {
  const splitStr = str.toLowerCase().split(' ');
  let returnValue = '';

  for (let i = 0; i < splitStr.length; i += 1) {
    if (splitStr.length[i] < splitStr.length) {
      splitStr[i].charAt(0).toUpperCase();
    }

    returnValue = splitStr.join(' ');
  }

  return returnValue;
}

export function minecraftNameToWords(minecraftName) {
  return minecraftName
    .replace('minecraft:', '')
    .split('_')
    .map((word) => `${word.charAt(0).toUpperCase()}${word.substring(1)}`)
    .join(' ');
}
