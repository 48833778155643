import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import FasilHome from '../home';
import FasilDocumentation from '../documentation';
import FasilStatisticsPlayer from '../statistics/player';
import FasilStatisticsServer from '../statistics/server';

function FasilRouter() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<FasilHome />} />
        <Route exact path="/home" element={<FasilHome />} />
        <Route exact path="/docs" element={<FasilDocumentation />} />
        <Route exact path="/player-stats" element={<FasilStatisticsPlayer />} />
        <Route exact path="/server-stats" element={<FasilStatisticsServer />} />
      </Routes>
    </BrowserRouter>
  );
}

export default FasilRouter;
