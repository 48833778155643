import React from 'react';
import useAxios from 'use-axios';
import PlayerSelector from './player-selector';
import CategorySelector from '../../ui/category-selector';
import PlayerStatsTable from './player-stats-table';
import ServerSummary from './server-summary';
import FasilPage from '../../ui/page';

function FasilStatisticsPlayer() {
  const [playerUuid, setPlayerUuid] = React.useState('no_player_selected');
  const [category, setCategory] = React.useState('no_category_selected');
  const [buildDataTable, setBuildDataTable] = React.useState(false);
  const { data: serverSummary } = useAxios('/api/v2/stats/summary/season/6/server');

  React.useEffect(() => {
    React.startTransition(() => {
      setBuildDataTable(playerUuid !== 'no_player_selected' && category !== 'no_category_selected');
    });
  }, [playerUuid, category]);

  const playerChangeCallback = (selectedPlayerUuid) => {
    React.startTransition(() => {
      setPlayerUuid(selectedPlayerUuid);
    });
  };

  const categoryChangeCallback = (selectedCategory) => {
    React.startTransition(() => {
      setCategory(selectedCategory);
    });
  };

  return (
    <React.Suspense fallback="Loading...">
      <FasilPage>
        <div>
          <ServerSummary serverSummary={serverSummary} />
        </div>
        <div>
          <PlayerSelector changeCallback={playerChangeCallback} />
          <CategorySelector changeCallback={categoryChangeCallback} />
        </div>
        {
        buildDataTable
          ? (
            <PlayerStatsTable
              playerUuid={playerUuid}
              category={category}
              season={6}
              serverSummary={serverSummary}
            />
          )
          : null
      }
      </FasilPage>
    </React.Suspense>
  );
}

export default FasilStatisticsPlayer;
