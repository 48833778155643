import React from 'react';
import PropTypes from 'prop-types';
import {
  Box
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import CategoryTopPlayersDialog from './category-top-players-dialog';
import { minecraftNameToWords } from '../../util/string-utils';

function CategoryStatsTable(props) {
  const { category, stats } = props;
  const [selection, setSelection] = React.useState('');
  const [dialogData, setDialogData] = React.useState({});
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    if (dialogData?.season) {
      setOpen(true);
    }
  }, [dialogData]);

  React.useEffect(() => {
    const fetchDialogData = async () => {
      const payload = await axios(`/api/v2/stats/server/season/6/category/${category}/minecraft-name/${selection}/top-players/limit/10`);
      setDialogData(payload.data);
    };

    if (selection !== '') {
      fetchDialogData();
    }
  }, [selection]);

  const handleClose = () => {
    setOpen(false);
  };

  const columns = [
    {
      field: 'name',
      headerName: category,
      sortable: false,
      width: 300
    },
    {
      field: 'count',
      headerName: 'Count',
      type: 'number',
      sortable: false,
      valueGetter: (params) => new Intl.NumberFormat().format(params.row.count)
    }
  ];

  const rows = stats.map((stat) => ({
    id: `${category}-${stat.minecraftName}`,
    name: minecraftNameToWords(stat.minecraftName),
    count: stat.count
  }));

  const pageSize = 10;

  const handleRowClick = (event) => {
    const minecraftName = event.id.replace(`${category}-minecraft:`, '');
    setSelection(minecraftName);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize
            }
          }
        }}
        pageSizeOptions={[5, 10, 15, 20, 25]}
        checkboxSelection={false}
        onRowClick={handleRowClick}
      />
      <CategoryTopPlayersDialog
        onClose={handleClose}
        open={open}
        category={category}
        dialogData={dialogData}
      />
    </Box>
  );
}

CategoryStatsTable.propTypes = {
  category: PropTypes.string.isRequired,
  stats: PropTypes.arrayOf(PropTypes.shape(
    { minecraftName: PropTypes.string.isRequired, count: PropTypes.number.isRequired }
  ).isRequired).isRequired
};

export default CategoryStatsTable;
