import React from 'react';
import PropTypes from 'prop-types';
import {
  Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography
} from '@mui/material';
import { formatNumber } from '../../util/string-utils';

function ServerSummary(props) {
  const { serverSummary } = props;

  const lastUpdated = new Date(serverSummary.updatedDate);
  const lastUpdatedDate = lastUpdated.toLocaleDateString();
  const lastUpdatedTime = lastUpdated.toLocaleTimeString();
  const lastUpdatedString = `${lastUpdatedDate} ${lastUpdatedTime}`;

  return (
    <Box sx={{ width: '500px' }} size="sm" pb={2}>
      <Typography gutterBottom>
        Last update:
        {' '}
        {lastUpdatedString}
      </Typography>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Crafted</TableCell>
              <TableCell>Killed</TableCell>
              <TableCell>Mined</TableCell>
              <TableCell>Placed</TableCell>
              <TableCell>Used</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                {formatNumber(serverSummary.crafted)}
              </TableCell>
              <TableCell>
                {formatNumber(serverSummary.killed)}
              </TableCell>
              <TableCell>
                {formatNumber(serverSummary.mined)}
              </TableCell>
              <TableCell>
                {formatNumber(serverSummary.placed)}
              </TableCell>
              <TableCell>
                {formatNumber(serverSummary.used)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

ServerSummary.propTypes = {
  serverSummary: PropTypes.shape({
    crafted: PropTypes.number.isRequired,
    killed: PropTypes.number.isRequired,
    mined: PropTypes.number.isRequired,
    placed: PropTypes.number.isRequired,
    used: PropTypes.number.isRequired,
    season: PropTypes.number,
    updatedDate: PropTypes.number.isRequired
  }).isRequired
};

export default ServerSummary;
