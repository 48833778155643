import React from 'react';
import { Typography } from '@mui/material';
import FasilPage from '../ui/page';

function FasilDocumentation() {
  return (
    <FasilPage>
      <Typography>FASIL docs.</Typography>
    </FasilPage>
  );
}

export default FasilDocumentation;
