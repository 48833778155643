import React from 'react';
import PropTypes from 'prop-types';
import useAxios from 'use-axios';
import { Box, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { formatNumber, minecraftNameToWords, percentage } from '../../util/string-utils';

function PlayerStatsTable(props) {
  const {
    playerUuid, category, season, serverSummary
  } = props;
  if (category === 'no_category_selected') {
    return null;
  }
  const { data } = useAxios(`/api/v2/stats/player/${playerUuid}/season/${season}/category/${category}/limit/0`);

  if (typeof (data) === 'string') {
    return null;
  }

  const columns = [
    {
      field: 'name',
      headerName: category,
      sortable: false,
      width: 300
    },
    {
      field: 'count',
      headerName: 'Count',
      type: 'number',
      sortable: false,
      valueGetter: (params) => new Intl.NumberFormat().format(params.row.count)
    }
  ];

  const rows = data.stats.map((stat) => ({
    id: `${category}-${stat.minecraftName}`,
    name: minecraftNameToWords(stat.minecraftName),
    count: stat.count
  }));

  let playerTotal = 0;
  data.stats.forEach((stat) => {
    playerTotal += stat.count;
  });

  const pageSize = 10;

  return (
    <Box sx={{ width: '100%' }}>
      <Typography gutterBottom>
        Total:
        {' '}
        {formatNumber(playerTotal)}
        {' '}
        (
        {percentage(playerTotal, serverSummary[category.toLowerCase()])}
        {' '}
        of the server total)
      </Typography>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize
            }
          }
        }}
        pageSizeOptions={[5, 10, 15, 20, 25]}
        checkboxSelection={false}
        disableRowSelectionOnClick
      />
    </Box>
  );
}

PlayerStatsTable.defaultProps = {
  season: 6
};

PlayerStatsTable.propTypes = {
  serverSummary: PropTypes.shape({
    crafted: PropTypes.number.isRequired,
    killed: PropTypes.number.isRequired,
    mined: PropTypes.number.isRequired,
    placed: PropTypes.number.isRequired,
    used: PropTypes.number.isRequired,
    season: PropTypes.number,
    updatedDate: PropTypes.number.isRequired
  }).isRequired,
  playerUuid: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  season: PropTypes.number
};

export default PlayerStatsTable;
