import React from 'react';
import axios from 'axios';
import CategorySelector from '../../ui/category-selector';
import CategoryStatsTable from './category-stats-table';
import FasilPage from '../../ui/page';

function FasilStatisticsServer() {
  const [category, setCategory] = React.useState('no_category_selected');
  const [tableData, setTableData] = React.useState({});

  React.useEffect(() => {
    React.startTransition(() => {
      if (category === 'no_category_selected') {
        setTableData({});
      } else {
        const fetchTableData = async () => {
          const payload = await axios(`api/v2/stats/server/season/6/category/${category}/limit/0`);
          setTableData(payload.data);
        };

        fetchTableData();
      }
    });
  }, [category]);

  const categoryChangeCallback = (selectedCategory) => {
    React.startTransition(() => {
      setCategory(selectedCategory);
    });
  };

  let table = null;

  if (tableData?.stats?.length) {
    table = (
      <CategoryStatsTable category={category} stats={tableData.stats} />
    );
  }

  return (
    <React.Suspense fallback="Loading...">
      <FasilPage>
        <CategorySelector changeCallback={categoryChangeCallback} />
        {table}
      </FasilPage>
    </React.Suspense>
  );
}

export default FasilStatisticsServer;
