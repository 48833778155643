import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent, DialogTitle, Table, TableBody, TableCell, TableContainer, TableHead, TableRow
} from '@mui/material';
import { formatNumber, percentage } from '../../util/string-utils';

function CategoryTopPlayersDialog(props) {
  const {
    onClose, open, category, dialogData
  } = props;

  if (!open) {
    return null;
  }

  const { serverTotal, topPlayers } = dialogData;
  const rows = topPlayers.map((topPlayer) => {
    const count = formatNumber(topPlayer.count);
    const playerPercentage = percentage(topPlayer.count, serverTotal);
    return (
      <TableRow key={`top_player-${category}-${topPlayer.fasilPlayer.uuid}`}>
        <TableCell>
          {topPlayer.fasilPlayer.name}
        </TableCell>
        <TableCell>
          {count}
        </TableCell>
        <TableCell>
          {playerPercentage}
        </TableCell>
      </TableRow>
    );
  });

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>Top 10 Players</DialogTitle>
      <DialogContent>
        <TableContainer>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Player</TableCell>
                  <TableCell>Count</TableCell>
                  <TableCell>% of Server</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows}
              </TableBody>
            </Table>
          </TableContainer>
        </TableContainer>
      </DialogContent>
    </Dialog>
  );
}

CategoryTopPlayersDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  category: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  dialogData: PropTypes.object.isRequired
};

export default CategoryTopPlayersDialog;
