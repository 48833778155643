import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from '@mui/material';

function CategorySelector(props) {
  const [selectedCategory, setSelectedCategory] = React.useState('no_category_selected');
  const { changeCallback } = props;

  const changeSelectedCategory = (event) => {
    const selectedValue = event.target.value;
    setSelectedCategory(selectedValue);
    changeCallback(selectedValue);
  };

  return (
    <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
      <InputLabel id="category-select-label">Select a category</InputLabel>
      <Select
        labelId="category-select-label"
        id="category-select"
        value={selectedCategory}
        label="Selected category"
        onChange={changeSelectedCategory}
      >
        <MenuItem value="no_category_selected">
          <em>None</em>
        </MenuItem>
        <MenuItem value="CRAFTED">Crafted</MenuItem>
        <MenuItem value="KILLED">Killed</MenuItem>
        <MenuItem value="MINED">Mined</MenuItem>
        <MenuItem value="PLACED">Placed</MenuItem>
        <MenuItem value="USED">Used</MenuItem>
      </Select>
    </FormControl>
  );
}

CategorySelector.propTypes = {
  changeCallback: PropTypes.func.isRequired
};

export default CategorySelector;
